.content {
  color: white;
  text-align: center;
}
.MBBeVoIconCard__wrapper {
  max-width: 160px;
  max-height: 180px;
  margin: auto;
}
.MBBeVoIconCard__content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding-right: calc(var(--wb-grid-gutter-width) * 0.5);
  border-right: 1px solid #666666;
  margin: auto;
}

.wb-content-slider__content > *:last-child .MBBeVoIconCard__content {
  border-right: 0px solid #666666;
}
.MBBeVoIconCard__icon-wrapper {
  width: 86px;
  height: 86px;
  margin: auto;
  vertical-align: middle;
  line-height: 86px;
}
.MBBeVoIconCard__icon-content {
  color: white;
  vertical-align: middle;
  text-align: center;
}
.MBBeVoIconCard__icon {
  vertical-align: middle;
  text-align: center;
  width: 70px;
  height: 70px;
}
.MBBeVoIconCard__text-wrapper {
  width: 160px;
  height: 54px;
}
.MBBeVoIconCard__text-content {
  color: white;
  text-align: center;
}
.MBBeVoIconCard__button-anchor {
  width: 160px;
  background-color: #00adef;
  color: white;
}
.MBBeVoIconCard__button-icon {
  height: 10px;
  width: 10px;
  margin-right: 4px;
}

.cursorPointer {
  cursor: pointer;
}
