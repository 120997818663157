.content {
  color: white;
  text-align: center;
}
.MBBeVoIconCard__mobile-wrapper {
  max-width: 160px;
  max-height: 180px;
  margin: auto;
}
.MBBeVoIconCard__mobile-content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding-right: calc(var(--wb-grid-gutter-width) * 0.5);

  border-right: 1px solid #666666;
  margin: auto;
}
.MBBeVoIconCard__mobile-icon-wrapper {
  width: 86px;
  height: 86px;
  margin: auto;
  vertical-align: middle;
  line-height: 86px;
}
.MBBeVoIconCard__mobile-icon-content {
  color: white;
  vertical-align: middle;
  text-align: center;
}
.MBBeVoIconCard__mobile-icon {
  vertical-align: middle;
  text-align: center;
  width: 70px;
  height: 70px;
}
.MBBeVoIconCard__mobile-text-wrapper {
  width: 160px;
  height: 54px;
  margin: 0 auto;
}
.MBBeVoIconCard__mobile-text-content {
  color: white;
  text-align: center;
}
.MBBeVoIconCard__mobile-button-anchor {
  width: 160px;
  background-color: #00adef;
  color: white;
}
.MBBeVoIconCard__mobile-button-icon {
  height: 10px;
  width: 10px;
  margin-right: 4px;
}

.wb-button {
  margin: 0 auto;
  display: flex;
}

.cursorPointer {
  cursor: pointer;
}

.hrIconLine {
  margin-top: 40px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  color: #666;
  height: 1px;
  border-top: 1px solid #666;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
}
