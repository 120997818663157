:host,:root {
	--wb-font-text: "MBCorpo Text","DaimlerCS-Regular",sans-serif;
}

.textWhite {
  color: white;
}

.spaceTopLarge {
  margin-top: 60px;
}

.spaceBottom {
  margin-bottom: 30px;
}

.visible {
  display: block;
}

.notVisible {
  display: none;
}

.highlightBgColor {
	background-color: #00adef;
	color: white;
}

.deactiveBackground {
  width: 100%;
  height: 100%;
  background-color: #000000e6;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
}

.lightColor {
  background-color: var(--wb-black) !important;
  opacity: 0.5 !important;
}

.darkColor {
  background-color: var(--wb-black) !important;
  opacity: 1 !important;
}

.makeStyles-app-1 {
  position: relative;
}

.foreground {
  z-index: 12;
}

.middleground {
  z-index: 5;
}

.showForSmartphone {
  display: none;
}

.hideForSmartphone {
  display: inline;
}

@media (max-width: 767px) {
  .showForSmartphone {
    display: inline;
  }

  .hideForSmartphone {
    display: none;
  }
}

@media (min-width: 768px) {
  .deactiveBackground {
    height: 0;
    opacity: 0;
    transition: opacity 400ms ease-in-out 0s, height 0ms ease-in-out 400ms;
  }

  .deactiveBackground.notVisible {
    display: block;
  }

  .deactiveBackground.visible {
    height: 100%;
    transition: opacity 400ms ease-in-out 0s;
  }
}
