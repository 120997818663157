.sheetTableDesktop {
  width: 100%;
  overflow: auto;
  max-height: 700px;
}

.sheetTable {
  width: 100%;
}

.iconCheck {
  color: #00adef;
}

.tableHeader {
  position: sticky;
  top: 0;
  background: white;
}

.dt-link {
  color: #0087bd;
}

a.dt-link {
  color: #0087bd;
}

.dt-link:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .sheetTable {
    width: 100%;
    height: 500px;
    display: block;
    overflow-x: auto;
  }
}
