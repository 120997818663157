.tableContainer {
  background: black;
  margin-top: 95px;
}

@media (min-width: 992px) {
  .tableDesktop {
    width: 56%;
  }
}
