/*.wb-header__logo-container {*/
/*	display: none !important;*/
/*}*/
/*.wb-header__claim-wrapper {*/
/*	display: none !important;*/
/*}*/


.headertablet {
  margin-left: 0px;
  margin-right: 0px;
}

.headerContainer {
  position: relative;
}

.mobileLanguageSwitch {
  margin-left: 170px !important;
}

.mobileLanguageItem {
  width: 26px !important;
}

/* .mobileHeader {
  display: none;
}*/

.notDesktop {
  display: flex;
}

.lockedScroll {
  overflow-y: hidden;
}

.wb-language-menu__item-inner {
  position: relative; /* Ensures the tooltip is positioned relative to this container */
}

/* .wb-header-nav--main{
  z-index: 0;
} */
/* Tooltip Container */
/* .tooltip-container {
  position: relative;
  z-index: 100; 
} */

/* #mainCategoryMenu { padding:60px !important ; }  */

/* Tooltip */
.tooltip {
  position: absolute;
  transform: translate(-75%, 50%);
  background-color: #fff;
  color: #333;
  padding: 12px 16px;
  font-size: 14px;
  white-space: nowrap;
  border: 1px solid #ccc;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  z-index: 9999; /* Ensure it is on top of all other elements */
  padding: 6px;
}

/* Tooltip Arrow */
.tooltip::before {
  content: '';
  position: absolute;
  top: 0px; 
  width: 0;
  height: 0;
  right: 24%; /* Position the arrow */
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #fff transparent; 
  transform: translateY(-100%);
}


/* .wb-language-menu__item-inner:hover .tooltip {
  opacity: 1;
  visibility: visible;
} */

/*.wb-header__logo-container .wb-header__logo .wb-header__claim-wrapper {*/
/*  bottom: -12px;*/
/*}*/

/*.wb-header__claim-wrapper::before {*/
/*  background-color: var(--wb-black);*/
/*  bottom: 0;*/
/*  content: '';*/
/*  left: 0;*/
/*  height: 12px;*/
/*  position: absolute;*/
/*  width: 100%;*/
/*}*/

@media (min-width: 992px) {
  .headertablet {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
}

@media (min-width: 1025px) {
  .notDesktop {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .mobileLanguageSwitch {
    margin-left: 500px !important;
  }
  /*   .mobileHeader {
    display: none;
  }
  .notDesktop {
    display: flex;
  } */
}

@media (max-width: 767px) {
  /*   .mobileHeader {
    display: flex;
  } */
}
