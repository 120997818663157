.prolog {
  color: white !important;
}

.accordionContainer {
  overflow: hidden;
}

.accordionButton {
  float: left;
  margin-top: 30px;
}

.accordionExpended {
}

.accordionShrinked {
  height: 230px;
}

.prologWidth {
  width: 70%;
}

.prologStyle {
  margin-top: 35px;
  white-space: pre-line;
}

.prolog__transition {
  width: 100%;
  background: -webkit-linear-gradient(white, white, white, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.prolog__transition:after {
  background: linear-gradient(to top, #000000, #000000, #000000, #00000000),
    no-repeat;
}

@media (max-width: 767px) {
  .prologWidth {
    width: 92%;
    margin: auto;
  }
  .accordionButton {
    float: left;
    margin-top: 30px;
    margin-left: 14px;
  }
  .prolog {
    color: white !important;
    margin-top: 55px;
  }
}

@media (min-width: 768px) and (max-width: 993px) {
  .prologWidth {
    width: 92%;
    margin: auto;
  }
  .accordionButton {
    float: left;
    margin-top: 30px;
    margin-left: 14px;
  }
  .prolog {
    color: white !important;
    margin-top: 30px;
  }
}
