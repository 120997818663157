.cstm-cookie-model p {
  margin-bottom: 20px;
}
.cstm-cookie-model p,
.cstm-cookie-model h1,
.cstm-cookie-model h2,
.cstm-cookie-model h3,
.cstm-cookie-model h4,
.cstm-cookie-model h5,
.cstm-cookie-model h6,
.cstm-cookie-model label,
.cstm-cookie-model .wb-tab-bar__wrapper .hydrated {
  -webkit-font-smoothing: antialiased;
  color: var(--wb-grey-20);

  font-family: DaimlerCS-Light, sans-serif;
}

#cookieModelContainer {
  z-index: 10000;
}

@media (min-width: 1024px) {
  .cstm-cookie-model wb-modal[mode='info'] .wb-modal__outer {
    -ms-flex-item-align: center;
    align-self: center;
    height: 85%;
    max-height: 100%;
    width: 56.5vw;
  }
}

.hide {
  display: none;
}

.container {
  z-index: 30;
}
