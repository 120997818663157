.VechicleSkeletonSmartphone .tooltipContainer {
	overflow: hidden;
}

.VechicleSkeletonSmartphone__image {
	width: 100%;
	height: auto;
}

.VechicleSkeletonSmartphone__img {
	height: 40px;
}

.VechicleSkeletonSmartphone__header {
	width: 100%;
}

.VechicleSkeletonSmartphone__container {
	display: block;
	margin-left: auto;
	margin-right: auto;
	z-index: 5;
}

.VechicleSkeletonSmartphone__transition {
	width: 100%;
	position: absolute;
	height: auto;
	display: block;
	margin-left: auto;
	margin-right: auto;
	z-index: 10;
	background: linear-gradient(to top, #000000, #00000080, #00000000, #00000000),
	no-repeat;
}

.VechicleSkeletonSmartphone__title {
	color: white;
	position: absolute;
	bottom: -36px;
	left: 32px;
}

@media (min-width: 768px) {
	.VechicleSkeletonSmartphone {
		display: none;
	}
}

.engineNavigatorSmartphone {
	top: 39%;
	left: 29%;
	position: absolute;

	z-index: 10;
}

.gearNavigatorSmartphone {
	top: 35%;
	left: 55%;
	position: absolute;

	z-index: 10;
}

.adBlueNavigatorSmartphone {
	top: 55%;
	left: 62%;
	position: absolute;

	z-index: 10;
}

.axleOilNavigatorSmartphone {
	top: 28%;
	left: 79%;
	position: absolute;

	z-index: 10;
}

.coolantNavigatorSmartphone {
	top: 56%;
	left: 22%;
	position: absolute;

	z-index: 10;
}
