.searchContainerStyle {
  position: absolute;
  top: 160px;
  left: 13%;
}

.searchInput {
  width: 486px;
}

.searchButton {
  float: left;
}

.inputSearch {
  float: left;
}

.textSpace {
  margin-top: 68px;
}

.searchCard {
  border: 1px solid #333333;
  background-color: #262626;
  width: 921px;
  /* height: 104px; */
  margin-bottom: 18px;
}

.cardContainer {
  margin-top: 20px;
}

.verticalLineCard {
  transform: rotate(90deg);
  width: 60px;
  position: absolute;
  left: 100px;
  top: 42px;
  color: grey;
  background-color: grey;
  border-color: grey;
}

.backButton {
  position: relative;
  left: 624px;
  color: #00adef;
  visibility: visible;
}

.firstColumnHeader {
  position: static;
  left: 24px;
  top: 20px;
}
.firstColumnText {
  position: absolute;
  top: 38px;
  left: 24px;
  color: #00adef;
}
.secondColumnHeader {
  /* position: absolute; */
  left: 160px;
  top: 20px;
}
.secondColumnText {
  /* position: absolute; */
  left: 160px;
  top: 54px;
  color: #00adef;
}
.cardIcon {
  position: absolute;
  right: 20px;
  top: 40px;
  color: #00adef;
}

@media (max-width: 767px) {
  .searchInput {
    width: 80%;
  }
  .searchContainerStyle {
    position: absolute;
    top: 160px;
    left: 4%;
    right:2%;
  }
  .backButton {
    position: absolute;
    color: #00adef;
    visibility: visible;
    left: -24px;
    top: -56px;
  }
  .searchCard {
    border: 1px solid #333333;
    background-color: #262626;
    width: auto !important;
    height: auto !important;
    margin-bottom: 18px;
  }
  .secondColumnText {
    position: static;
    /* left: 160px;
    top: 54px; */
    color: #00adef;
    width: 70%;
  }
}

@media (max-width: 1024px) {
  .backButton {
    position: absolute;
    color: #00adef;
    visibility: visible;
    left: -24px;
    top: -56px;
  }
  .searchCard {
    border: 1px solid #333333;
    background-color: #262626;
    width: 586px;
    height: 104px;
    margin-bottom: 18px;
  }
  .secondColumnText {
    /* position: absolute; */
    left: 160px;
    top: 54px;
    color: #00adef;
    width: 70%;
  }
}

@media (min-width: 1025px) and (max-width: 1400px) {
  .backButton {
    position: absolute;
    width: 90%;
    float: right;
    color: #00adef;
    visibility: visible;
  }
}



.totalcontentRow {
  display: flex;
  width: 100%;
}
.left-column {
  width: 25%;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}
.left-column::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%); /* Center the line vertically */
  width: 2px;
  height: 80%; /* Smaller vertical line */
  background-color: #ccc; /* Line color */
}
.right-column {
  width: 75%;
  /* background-color: #ffffff;  White background */
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}
.text {
  margin-bottom: 10px;
  margin-right: 4%;
}
.text-bold-blue {
  color: skyblue;
  font-size: 1.2em; /* Larger font size */
  font-weight: bold; /* Bold text */
}
.arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid black; /* Black arrow color */
}

.highlight {
  background-color: #00adef;
  color: #ffff;
  font-weight: bold;
}