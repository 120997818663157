.list-anchor {
  text-decoration: none;
}

.list-anchor:hover {
  color: var(--wb-color-highlight);
}

.width100 {
  width: 100%;
}

.Hydraulikol .Hydraulikol {
  display: none;
}
.Kraftstoff {
  display: 'none';
}
.Getriebeöl {
  display: 'none';
}
.Bremsflüssigkeit {
  display: 'none';
}

.Title {
  color: white !important;
}

.Divider {
  position: absolute;
  width: 38px;
}

.TopCategoryTitle {
  margin-top: 20px;
  margin-bottom: 20px;
}

.TopCategoryDescription {
  margin-top: 8px;
  color: white !important;
}

.MBBeVoTeaser__image {
  width: 100%;
}

@media (max-width: 767px) {
  .smartphoneView {
    margin-bottom: 30px;
  }
  .TopCategoryDescription {
    margin-top: 0px;
    color: white !important;
  }
}
