.dataPrivacy {
  display: none !important;
}

.contactContainer {
  background-color: white;
  padding: 50px;
}

.feedbackStyle {
  margin-top: 8px;
  white-space: pre-line;
}

.sendMailBtnStyle {
  max-width: 100%;
}

.formContainer {
  margin: 0px;
}

.rowStyle {
  margin-top: 14px;
  margin-bottom: 14px;
}

.error {
  color: #e1292b;
  margin-top: 2px;
}

.contactLabels {
  display: block;
}

@media (max-width: 767px) {
  .contactLabels {
    display: none !important;
  }
  .contactContainer {
    background-color: white;
    padding: 10px;
  }
  .mobileContact {
    width: 100%;
  }
  .formContainer {
    margin: 0px;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .contactLabels {
    display: none !important;
  }
  .contactContainer {
    background-color: white;
    padding: 10px;
  }
  .mobileContact {
    width: 100%;
    text-align: center;
  }
  .formContainer {
    margin: 0px;
    width: 100%;
  }
  .feedbackStyle {
    margin-top: 8px;
    white-space: pre-line;
  }
  .contactButton {
    text-align: center;
  }
}

@media (min-width: 1024px) {
  .feedbackStyle {
    margin-top: 0px;
    white-space: pre-line;
  }
  .contactButton {
    float: left;
  }
}
