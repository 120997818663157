.iconNavigationContainer {
  position: absolute;

  z-index: 10;
}

.iconStyle {
  color: #0078d6;
  position: absolute;
  fill: #0078d6;
}

.roundButtonStyle {
  width: 18px;
  height: 18px;
}

.tooltipIcon {
  color: #0078d6;
}

.noHover {
  pointer-events: none;
}

.tooltipStyle {
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 2px;
  left: -30px !important;

  background-color: #262626;
}

.iconNavigationContainer {
  height: 24px;
  width: 24px;
  transition: all 0.1s ease;
}

.iconNavigationContainer:hover {
  height: 30px;
  width: 30px;
  margin-left: -3px;
  margin-top: -3px;
}

.iconNavigationContainer div,
.iconNavigationContainer .tooltipContainer,
.iconNavigationContainer .roundButtonStyle,
.iconNavigationContainer .iconStyle {
  height: 100%;
  width: 100%;
}
