.VechicleSkeletonTablet .tooltipContainer {
  overflow: hidden;
}

.VechicleSkeletonTablet__image {
  width: 100%;
  height: auto;
}
.VechicleSkeletonTablet__img {
  height: 40px;
}
.VechicleSkeletonTablet__header {
  width: 100%;
}
.VechicleSkeletonTablet__container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
}
.VechicleSkeletonTablet__transition {
  position: absolute;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  background: linear-gradient(
      to right,
      #000000,
      #00000080,
      #00000000,
      #00000000
    ),
    no-repeat;
}

.VechicleSkeletonTablet__title {
  color: white;
  position: absolute;
  top: 85px;
  left: 85px;
}

@media (max-width: 767px) {
  .VechicleSkeletonTablet {
    display: none;
  }
}

@media (min-width: 993px) {
  .VechicleSkeletonTablet {
    display: none;
  }
}

.engineNavigator {
  top: 49%;
  left: 33%;
  position: absolute;

  z-index: 10;
}

.gearNavigator {
  top: 48%;
  left: 50%;
  position: absolute;

  z-index: 10;
}

.adBlueNavigator {
  top: 61%;
  left: 54%;
  position: absolute;

  z-index: 10;
}

.coolantNavigator {
  top: 66%;
  left: 25%;
  position: absolute;

  z-index: 10;
}
