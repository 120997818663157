.categoryDescription {
  color: white !important;
  position: absolute;
  bottom: 340px;
}

.bannerDesktop__transition {
  position: absolute;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  background: linear-gradient(
      to right,
      #000000,
      #00000080,
      #00000000,
      #00000000
    ),
    no-repeat;
}

.banner__image {
  max-width: 1440px;
}

@media (min-width: 768px) and (max-width: 993px) {
  .categoryDescription {
    color: white !important;
    position: absolute;
    bottom: 340px;
  }

  .banner__image {
    max-width: 100%;
  }
  .categoryDescription {
    color: white !important;
    position: relative;
    bottom: 115px;
    margin-left: 14px;
  }
  .bannerDesktop__transition {
    position: absolute;
    height: 360px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    background: linear-gradient(
        to right,
        #000000,
        #00000080,
        #00000000,
        #00000000
      ),
      no-repeat;
  }
}

@media (max-width: 767px) {
  .banner__image {
    max-width: 100%;
  }
  .bannerDesktop__transition {
    width: 100%;
    position: absolute;
    height: 163px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    background: linear-gradient(
        to top,
        #000000,
        #00000080,
        #00000000,
        #00000000
      ),
      no-repeat;
  }
  .categoryDescription {
    color: white !important;
    position: relative;
    bottom: 0px;
    margin-left: 14px;
  }
}

@media (min-width: 768px) {
  .categoryDescription {
    color: white !important;
    position: absolute;
    bottom: 200px;
  }
}

@media (min-width: 993px) {
  .categoryDescription {
    color: white !important;
    position: absolute;
    bottom: 200px;
  }
}
