.wb-header-nav__control {
	outline-offset: 0
}

.wb-header-nav__control::-moz-focus-inner {
	border-style: none;
	padding: 0
}

.wb-header-nav__control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent;
	border: 0;
	font-family: inherit;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
	overflow: visible;
	padding: 0;
	text-transform: none
}

[dir=ltr] .wb-header-nav__control {
	text-align: left
}

[dir=rtl] .wb-header-nav__control {
	text-align: right
}

.wb-header-nav__control--icon-only, .wb-header-nav__control--profile {
	height: 48px;
	width: 48px
}

.wb-header-nav__control {
	align-items: center;
	background: transparent;
	border: none;
	color: var(--wb-grey-60);
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	min-height: 48px;
	min-width: 48px;
	outline: none;
	position: relative;
	text-decoration: none
}

.wb-language-menu {
	align-items: center;
	display: flex
}

[dir=ltr] .wb-language-menu {
	margin-left: 16px;
	margin-right: 16px
}

[dir=rtl] .wb-language-menu {
	margin-left: 16px;
	margin-right: 16px
}

@media (min-width: 1280px) {
	[dir=ltr] .wb-language-menu {
		margin-left: 0
	}

	[dir=rtl] .wb-language-menu {
		margin-right: 0
	}
}

.wb-language-menu__item {
	align-items: center;
	cursor: pointer;
	display: flex;
	justify-content: center;
	padding: 0;
	text-decoration: none
}

[dir=ltr] .wb-language-menu__item {
	margin-right: var(--wb-spacing-3xs)
}

[dir=rtl] .wb-language-menu__item {
	margin-left: var(--wb-spacing-3xs)
}

@media (max-width: 1439.98px) {
	.wb-language-menu__item {
		height: 48px;
		width: 48px
	}

	[dir=ltr] .wb-language-menu__item {
		margin-right: 0
	}

	[dir=rtl] .wb-language-menu__item {
		margin-left: 0
	}
}

.wb-language-menu__item-inner {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	align-items: center;
	border-radius: 2px;
	color: var(--wb-grey-85);
	display: flex;
	font: inherit;
	font-family: MBCorpo Text, DaimlerCS-Regular, sans-serif;
	font-size: .75rem;
	font-style: normal;
	font-weight: 400;
	height: 20px;
	justify-content: center;
	letter-spacing: .01875em;
	line-height: 1rem;
	width: 24px
}

.wb-language-menu__item-inner sup {
	font-size: 9px;
	line-height: 0;
	position: relative;
	top: -.8em;
	vertical-align: baseline
}

@media (min-width: 1280px) {
	.wb-language-menu__item-inner {
		background-color: transparent
	}
}

.wb-language-menu__item--active, .wb-language-menu__item:focus, .wb-language-menu__item:hover {
	outline: none
}

.wb-language-menu__item--active .wb-language-menu__item-inner, .wb-language-menu__item:focus .wb-language-menu__item-inner, .wb-language-menu__item:hover .wb-language-menu__item-inner {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	color: var(--wb-white);
	font: inherit;
	font-family: MBCorpo Text, DaimlerCS-Regular, sans-serif;
	font-size: .75rem;
	font-style: normal;
	font-weight: 700;
	letter-spacing: .3px;
	line-height: 1rem
}

.wb-language-menu__item--active .wb-language-menu__item-inner sup, .wb-language-menu__item:focus .wb-language-menu__item-inner sup, .wb-language-menu__item:hover .wb-language-menu__item-inner sup {
	font-size: 9px;
	line-height: 0;
	position: relative;
	top: -.8em;
	vertical-align: baseline
}

.wb-language-menu__item--active .wb-language-menu__item-inner {
	background-color: var(--wb-grey-15)
}

[dir=ltr] .wb-language-menu__item:last-child .wb-language-menu__item-inner {
	margin-right: 0
}

[dir=rtl] .wb-language-menu__item:last-child .wb-language-menu__item-inner {
	margin-left: 0
}

.wb-header-nav {
	--highlight-color: var(--wb-color-highlight);
	display: none;
	z-index: 1
}

.wb-header-nav__control {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font: inherit;
	font-family: MBCorpo Text, DaimlerCS-Regular, sans-serif;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem
}

.wb-header-nav__control sup {
	font-size: 9px;
	line-height: 0;
	position: relative;
	top: -1.1em;
	vertical-align: baseline
}

.wb-header-nav__control.wb-nav__control--active:after {
	background: var(--highlight-color);
	content: "";
	height: 1px;
	position: absolute;
	top: -1px;
	width: 100%
}

[dir=ltr] .wb-header-nav__control.wb-nav__control--active:after {
	-webkit-animation-duration: .15s;
	animation-duration: .15s;
	-webkit-animation-name: slidein;
	animation-name: slidein;
	-webkit-animation-timing-function: ease-out;
	animation-timing-function: ease-out;
	left: 0;
	transform-origin: 0 50%
}

[dir=rtl] .wb-header-nav__control.wb-nav__control--active:after {
	-webkit-animation-duration: .15s;
	animation-duration: .15s;
	-webkit-animation-name: slidein;
	animation-name: slidein;
	-webkit-animation-timing-function: ease-out;
	animation-timing-function: ease-out;
	right: 0;
	transform-origin: 100% 50%
}

.wb-header-nav__control:active, .wb-header-nav__control:focus, .wb-header-nav__control:hover {
	color: var(--wb-white);
	text-decoration: none
}

.wb-header-nav__control wb-icon {
	height: 16px;
	pointer-events: none;
	width: 16px
}

.wb-header-nav__control--offset-end {
	justify-self: flex-end
}

[dir=ltr] .wb-header-nav__control--offset-end {
	margin-left: auto
}

[dir=rtl] .wb-header-nav__control--offset-end {
	margin-right: auto
}

.wb-header-nav__control--offset-start {
	justify-self: flex-start
}

[dir=ltr] .wb-header-nav__control--offset-start {
	padding-left: 0 !important
}

[dir=rtl] .wb-header-nav__control--offset-start {
	padding-right: 0 !important
}

.wb-header-nav__control--icon-only {
	padding: 0
}

.wb-header-nav__control--icon-only wb-icon {
	color: var(--wb-white)
}

[dir=ltr] .wb-header-nav__control--icon-only wb-icon {
	margin-right: 0
}

[dir=rtl] .wb-header-nav__control--icon-only wb-icon {
	margin-left: 0
}

.wb-header-nav__control--profile {
	padding: 0
}

.wb-header-nav__control--profile wb-aspect-ratio {
	border-radius: 50%;
	height: 20px;
	overflow: hidden;
	width: 20px
}

.wb-header-nav__control--icon-only:focus, .wb-header-nav__control--icon-only:hover, .wb-header-nav__control--profile:focus, .wb-header-nav__control--profile:hover {
	background-color: var(--wb-grey-15)
}

.wb-header-nav__control--active.wb-header-nav__control--icon-only, .wb-header-nav__control--active.wb-header-nav__control--profile {
	background-color: var(--wb-grey-15)
}

.wb-header-nav__imprint {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font: inherit;
	font-family: MBCorpo Text, DaimlerCS-Regular, sans-serif;
	font-size: .75rem;
	font-style: normal;
	font-weight: 400;
	letter-spacing: .01875em;
	line-height: 1rem
}

[dir=ltr] .wb-header-nav__imprint {
	margin-right: 32px
}

[dir=rtl] .wb-header-nav__imprint {
	margin-left: 32px
}

.wb-header-nav__imprint sup {
	font-size: 9px;
	line-height: 0;
	position: relative;
	top: -.8em;
	vertical-align: baseline
}

.wb-header-nav__imprint .wb-link {
	color: var(--wb-grey-60)
}

.wb-header-nav__imprint:focus-within .wb-link, .wb-header-nav__imprint:hover .wb-link {
	color: var(--wb-white)
}

.wb-header-nav--meta {
	align-items: center;
	height: 96px;
	justify-content: flex-end;
	padding: 24px 0;
	position: absolute;
	top: 0
}

[dir=ltr] .wb-header-nav--meta {
	left: 242px;
	right: 0
}

[dir=rtl] .wb-header-nav--meta {
	left: 0;
	right: 242px
}

.wb-header-nav--main {
	border-bottom: 1px solid var(--wb-black);
	flex-direction: row;
	position: relative
}

.wb-header-nav--main .wb-header-nav__control {
	min-height: 56px;
	padding: 0 24px
}

[dir=ltr] .wb-header-nav--main .wb-header-nav__control > wb-icon {
	margin-left: 8px;
	margin-right: 8px
}

[dir=rtl] .wb-header-nav--main .wb-header-nav__control > wb-icon {
	margin-left: 8px;
	margin-right: 8px
}

@media (min-width: 1280px) {
	.wb-header-nav--main .wb-header-nav__control--active:after {
		background: var(--highlight-color);
		content: "";
		height: 1px;
		position: absolute;
		top: -1px;
		width: 100%
	}

	[dir=ltr] .wb-header-nav--main .wb-header-nav__control--active:after {
		-webkit-animation-duration: .15s;
		animation-duration: .15s;
		-webkit-animation-name: slide-in;
		animation-name: slide-in;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		left: 0;
		transform-origin: 0 50%
	}

	[dir=rtl] .wb-header-nav--main .wb-header-nav__control--active:after {
		-webkit-animation-duration: .15s;
		animation-duration: .15s;
		-webkit-animation-name: slide-in;
		animation-name: slide-in;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		right: 0;
		transform-origin: 100% 50%
	}
}

.wb-header-nav--main:before {
	background-color: var(--wb-grey-15);
	content: "";
	height: 1px;
	position: absolute;
	top: -1px;
	width: 100vw
}

[dir=ltr] .wb-header-nav--main:before {
	left: 50%;
	margin-left: -50vw
}

[dir=rtl] .wb-header-nav--main:before {
	margin-right: -50vw;
	right: 50%
}

@media (max-width: 767.98px) {
	.wb-header-nav--mobile {
		display: flex
	}
}

@media (min-width: 768px) and (max-width: 1279px) {
	.wb-header-nav--tablet {
		align-items: center;
		display: flex
	}
}

@media (min-width: 1280px) {
	.wb-header-nav--desktop {
		display: flex
	}
}

[dir=ltr] .wb-header-nav--start .wb-header-nav__control + .wb-header-nav__control {
	margin-left: 1rem
}

[dir=rtl] .wb-header-nav--start .wb-header-nav__control + .wb-header-nav__control {
	margin-right: 1rem
}

[dir=ltr] .wb-header-nav--start .wb-header-nav__control + .wb-header-nav__control.wb-header-nav__control--icon-only, [dir=ltr] .wb-header-nav--start .wb-header-nav__control + .wb-header-nav__control.wb-header-nav__control--language, [dir=ltr] .wb-header-nav--start .wb-header-nav__control + .wb-header-nav__control.wb-header-nav__control--profile {
	margin-left: 0
}

[dir=rtl] .wb-header-nav--start .wb-header-nav__control + .wb-header-nav__control.wb-header-nav__control--icon-only, [dir=rtl] .wb-header-nav--start .wb-header-nav__control + .wb-header-nav__control.wb-header-nav__control--language, [dir=rtl] .wb-header-nav--start .wb-header-nav__control + .wb-header-nav__control.wb-header-nav__control--profile {
	margin-right: 0
}

[dir=ltr] .wb-header-nav--end .wb-header-nav__control + .wb-header-nav__control {
	margin-right: 1rem
}

[dir=rtl] .wb-header-nav--end .wb-header-nav__control + .wb-header-nav__control {
	margin-left: 1rem
}

[dir=ltr] .wb-header-nav--end .wb-header-nav__control + .wb-header-nav__control.wb-header-nav__control--icon-only, [dir=ltr] .wb-header-nav--end .wb-header-nav__control + .wb-header-nav__control.wb-header-nav__control--language, [dir=ltr] .wb-header-nav--end .wb-header-nav__control + .wb-header-nav__control.wb-header-nav__control--profile {
	margin-right: 0
}

[dir=rtl] .wb-header-nav--end .wb-header-nav__control + .wb-header-nav__control.wb-header-nav__control--icon-only, [dir=rtl] .wb-header-nav--end .wb-header-nav__control + .wb-header-nav__control.wb-header-nav__control--language, [dir=rtl] .wb-header-nav--end .wb-header-nav__control + .wb-header-nav__control.wb-header-nav__control--profile {
	margin-left: 0
}

@-webkit-keyframes slide-in {
	0% {
		transform: scaleX(0)
	}
	to {
		transform: scaleX(1)
	}
}

@keyframes slide-in {
	0% {
		transform: scaleX(0)
	}
	to {
		transform: scaleX(1)
	}
}

.wb-new-colors .wb-header-nav {
	--highlight-color: var(--wb-blue-50)
}

wb-headers {
	background: var(--wb-black);
	display: block;
	max-width: 100%;
	overflow-x: hidden;
	z-index: 1300
}

wb-headers .wb-header__content-width {
	position: relative;
	width: 90%
}

[dir=ltr] wb-headers .wb-header__content-width {
	margin-left: auto;
	margin-right: auto
}

[dir=rtl] wb-headers .wb-header__content-width {
	margin-left: auto;
	margin-right: auto
}

@media (min-width: 768px) {
	wb-headers .wb-header__content-width {
		max-width: 1680px;
		width: 86%
	}
}

@media (min-width: 1920px) {
	wb-headers .wb-header__content-width {
		width: 100%
	}
}

wb-headers .wb-header__logo {
	z-index: 1
}

@media (max-width: 1279.98px) {
	wb-headers .wb-header__logo {
		position: absolute
	}

	[dir=ltr] wb-headers .wb-header__logo {
		left: 50%
	}

	[dir=rtl] wb-headers .wb-header__logo {
		right: 50%
	}

	wb-headers .wb-header__claim-wrapper {
		display: none
	}

	wb-headers .wb-header__nav-container {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: space-between;
		position: absolute;
		top: 0;
		width: 100%
	}

	[dir=ltr] wb-headers .wb-header__nav-container {
		left: 0;
		right: 0
	}

	[dir=rtl] wb-headers .wb-header__nav-container {
		left: 0;
		right: 0
	}
}

@media (max-width: 767.98px) {
	wb-headers .wb-header__logo-container {
		height: 80px
	}

	wb-headers .wb-header__logo {
		height: 80px;
		padding: 20px;
		width: 80px
	}

	[dir=ltr] wb-headers .wb-header__logo {
		margin-left: -40px
	}

	[dir=rtl] wb-headers .wb-header__logo {
		margin-right: -40px
	}

	wb-headers .wb-header__star {
		height: 40px;
		width: 40px
	}
}

@media (min-width: 768px) and (max-width: 1279px) {
	wb-headers .wb-header__logo-container {
		height: 96px
	}

	wb-headers .wb-header__logo {
		height: 96px;
		padding: 16px;
		width: 96px
	}

	[dir=ltr] wb-headers .wb-header__logo {
		margin-left: -48px
	}

	[dir=rtl] wb-headers .wb-header__logo {
		margin-right: -48px
	}

	wb-headers .wb-header__star {
		height: 64px;
		width: 64px
	}
}

@media (min-width: 1280px) {
	wb-headers .wb-header__logo-container {
		height: 96px
	}

	wb-headers .wb-header__logo {
		line-height: 0;
		position: absolute;
		top: 16px;
		width: 242px
	}

	[dir=ltr] wb-headers .wb-header__logo {
		left: 0
	}

	[dir=rtl] wb-headers .wb-header__logo {
		right: 0
	}

	wb-headers .wb-header__star {
		height: 64px;
		width: 64px
	}

	wb-headers .wb-header__claim-wrapper {
		bottom: 2px;
		display: block;
		position: absolute
	}

	[dir=ltr] wb-headers .wb-header__claim-wrapper {
		right: 0
	}

	[dir=rtl] wb-headers .wb-header__claim-wrapper {
		left: 0
	}

	wb-headers .wb-header__nav-container {
		display: block
	}

	wb-headers .wb-header__claim {
		color: var(--wb-white);
		width: 112px
	}

	wb-headers .wb-header__claim--en {
		display: block
	}

	wb-headers .wb-header__claim--de {
		display: none;
		padding-bottom: 2px
	}
}

[lang=de] .wb-header__claim--de {
	display: block
}

[lang=de] .wb-header__claim--en {
	display: none
}