.nav-web-wrapper1 {
  left: 0;
  top: 0;
  position: absolute;
  overflow: hidden;
}
.cstm-header-nave-close {
  width: 100%;
}
.cstm-header-nave-close .wb-button {
  float: right;
  margin-top: 20px;
  margin-right: 20px;
  border: 0px solid var(--wb-grey-70);
}
.nav-web-parent {
  height: 100%;
  margin-left: 24px;
}
.nav-web-child {
  margin-left: 580px;
}

.childDialog {
  min-height: 60%;
}

.dialogNav {
  width: fit-content;
}

.lastMenuItem {
  height: 22px;
  line-height: 20px;
}

wb-header-flyout[open] {
  top: 0;
}

#mainCategoryMenu .wb-header-flyout__nav-items {
  padding: 0 var(--wb-spacing-m);
}

#mainCategoryMenu .wb-header-flyout__nav-link {
  border-radius: 4px;
  border: none;
  color: var(--wb-grey-40);
  outline: none;
  padding: 0.25rem 0;
  transition: all 400ms ease-in-out;
}

#mainCategoryMenu #subCategoryMenu .wb-header-flyout__nav-link {
  padding-left: 1rem;
}

#mainCategoryMenu .wb-header-flyout__nav-link:active,
#mainCategoryMenu .wb-header-flyout__nav-link:focus {
  border: none;
  outline: none;
}

#mainCategoryMenu .wb-header-flyout__nav-link:hover {
  background-color: var(--wb-grey-85);
  border: none;
  color: var(--wb-black);
  outline: none;
  padding-left: 1rem;
}

#mainCategoryMenu #subCategoryMenu .wb-header-flyout__nav-link:hover {
  padding-left: 2rem;
}

#mainCategoryMenu .wb-header-flyout__nav-link .wb-header-flyout__nav-item {
  color: inherit;
  margin: 0;
  padding: 0;
}

#mainCategoryMenu
  .wb-header-flyout__nav-link
  .wb-header-flyout__nav-item
  wb-icon,
#mainCategoryMenu
  .wb-header-flyout__nav-link
  .wb-header-flyout__nav-item
  .wb-header-flyout__control
  #mainCategoryMenu
  .wb-header-flyout__nav-link
  .wb-header-flyout__nav-item:hover
  wb-icon {
  color: inherit;
}

#mainCategoryMenu .wb-header-flyout__topbar-headline {
  align-items: center;
  display: flex;
  line-height: 1.5rem;
}

#mainCategoryMenu .wb-header-flyout__topbar-headline-icon {
  height: 3rem;
  margin-right: 0.25rem;
  width: 3rem;
  flex-shrink: 0;
}

#mainCategoryMenu .wb-header-flyout__nav-item-icon {
  height: 3rem;
  margin-right: 1rem;
  width: 3rem;
  flex-shrink: 0;
}

#mainCategoryMenu .wb-header-flyout__nav-item-label {
  line-height: 1.5rem;
}

@media (max-width: 767px) {
  .nav-web-parent,
  .nav-web-child {
    margin-left: 0px;
  }
  .wb-header-flyout-container {
    left: 0;
  }
  .childNav {
    overflow: auto !important;
    overflow-y: overlay !important;
    position: absolute !important;
    width: 100%;
  }
}

@media (min-width: 768px) {
  #mainCategoryMenu {
    display: block;
    height: 0;
    left: 0;
    overflow: hidden;
    padding: 120px 0 24px 0;
    position: absolute;
    top: 0;
    transition: height 0s ease-in-out 400ms;
    width: 100%;
  }

  #mainCategoryMenu.visible {
    height: 100vh;
    transition: height 0s ease-in-out 0s;
  }

  .nav-web-parent,
  #subCategoryMenu {
    display: block;
    height: 100%;
    margin-left: -100%;
    opacity: 0;
    transition: margin 400ms ease-in-out 0s, opacity 400ms ease-in-out 0s;
  }

  #mainCategoryMenu.visible .nav-web-parent,
  #subCategoryMenu.visible {
    margin-left: 24px;
    opacity: 1;
  }

  #mainCategoryMenu .wb-header-flyout-container {
    height: 100%;
    position: relative;
  }

  #mainCategoryMenu .wb-header-flyout-container__inner {
    display: flex;
  }

  #mainCategoryMenu wb-header-flyout {
    height: 100%;
    position: static;
  }

  #mainCategoryMenu .wb-header-flyout__body {
    background-color: transparent;
    height: 100%;
    position: static;
  }

  #mainCategoryMenu .wb-header-flyout__nav {
    background-color: var(--wb-white);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: 0;
    max-height: calc(100% - 80px);
    overflow-y: auto;
    width: 100%;
  }

  #mainCategoryMenu .wb-header-flyout__nav-items {
    background-color: var(--wb-white);
    padding: 0 var(--wb-spacing-xs);
  }

  #subCategoryMenu {
    max-height: 1062px;
  }

  #subCategoryMenu .childNav {
    position: static;
  }
}

@media (min-width: 768px) and (max-width: 1279px) {
  .wb-header-flyout-container__close-layer {
    left: 0;
  }

  [dir='ltr'] wb-header-flyout {
    left: 160px;
  }

  .menuTextDesktop {
    opacity: 1 !important;
    width: 85%;
  }
}

@media (min-width: 1280px) {
  #mainCategoryMenu {
    padding: 172px 76px 24px 76px;
  }

  #mainCategoryMenu .wb-header-flyout__topbar-outer {
    margin: 0;
    padding: 0 var(--wb-spacing-m);
  }

  #mainCategoryMenu .wb-header-flyout__nav-items li {
    margin: 0;
  }
  .nav-web-child .wb-header-flyout__body {
    height: auto;
  }

  #subCategoryMenu {
    max-height: 854px;
  }

  [dir='ltr'] .wb-header-flyout-container__inner {
    margin-left: 0px;
    margin-right: auto;
  }
  .wb-header-flyout-container__close-layer {
    left: 0;
  }
  .childDialogContainer {
    width: 350px;
  }
  .menuTextDesktop {
    opacity: 1 !important;
  }
  .desktopMarginChevron {
    margin-right: 70px;
  }
}

@media (min-width: 1920px) {
  #mainCategoryMenu {
    padding: 172px 0 24px 0;
  }
  #mainCategoryMenu.visible .nav-web-parent {
    margin-left: 0;
  }
}
