.timeLabel {
	float: right;
}

.htmlContainer {
	width: 100%;
	background-color: white;
	padding: 50px;
	font-family: var(--wb-font-text);
}

.htmlContainer figure.table {
	margin-top: 1em;
	margin-bottom: 1em;
}

.htmlContainer table {
	border-collapse: collapse;
}

.htmlContainer td, .htmlContainer th {
	border: 1px black solid;
}

.htmlContainer strong {
	font-size: 1rem;
	line-height: 1.5rem;
}