@media (min-width: 0px) {
  .MBBeVoContentSlider-mobile {
    display: block;
  }
  .MBBeVoContentSlider-web-tab {
    display: none;
  }
}

@media (min-width: 768px) {
  .MBBeVoContentSlider-mobile {
    display: none;
  }
  .MBBeVoContentSlider-web-tab {
    display: block;
  }
}

.MBBeVoContentSlider-mobile-space {
  margin-top: 75px;
}
