.MBBeVoContent__text {
  color: white;
  font-weight: 100;
}
.MBBeVoContent__spaceBottom {
  margin-bottom: 18px;
}
.MBBeVoContent__btnIcon {
  height: 10px;
  width: 10px;
  margin-right: 4px;
}
.MBBeVoContent__btnStyle {
  background-color: #00adef;
  color: white;
  width: 160px;
  position: absolute;
  bottom: 0;
}
.MBBeVoContent__image {
  width: 100%;
}
.MBBeVoContent__wrapper {
  margin-bottom: 30px;
}
.MBBeVoContent__text-bottom {
  margin-top: 20px;
}

.MBBeVoContent__title-wrapper .wb-type-copy-tertiary {
  margin-bottom: 15px;
  margin-top: 15px;
}

@media (min-width: 320px) {
  .MBBeVoContent__title-top {
    display: block;
  }
  .MBBeVoContent__title-bottom {
    display: none;
  }
}

@media (min-width: 768px) {
  .MBBeVoContent__title-top {
    display: none;
  }
  .MBBeVoContent__title-bottom {
    display: block;
  }
}

.contentText {
  white-space: pre-line;
}
