.VechicleSkeletonDesktop .tooltipContainer {
  overflow: visible;
}

[dir='ltr']
  .VechicleSkeletonDesktop
  .tooltipContainer[position='bottom'].wb-tooltip-host--flip:before,
[dir='ltr']
  .VechicleSkeletonDesktop
  .tooltipContainer[position='bottom']:not(.wb-tooltip-host--flip):before,
[dir='ltr']
  .VechicleSkeletonDesktop
  .tooltipContainer[position='end'].wb-tooltip-host--flip:before,
[dir='ltr']
  .VechicleSkeletonDesktop
  .tooltipContainer[position='end']:not(.wb-tooltip-host--flip):before,
[dir='ltr']
  .VechicleSkeletonDesktop
  .tooltipContainer[position='start'].wb-tooltip-host--flip:before,
[dir='ltr']
  .VechicleSkeletonDesktop
  .tooltipContainer[position='start']:not(.wb-tooltip-host--flip):before,
[dir='ltr']
  .VechicleSkeletonDesktop
  .tooltipContainer[position='top'].wb-tooltip-host--flip:before,
[dir='ltr']
  .VechicleSkeletonDesktop
  .tooltipContainer[position='top']:not(.wb-tooltip-host--flip):before {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.VechicleSkeletonDesktop
  .tooltipContainer[position='bottom']:not(.wb-tooltip-host--flip):before,
.VechicleSkeletonDesktop
  .tooltipContainer[position='top'].wb-tooltip-host--flip:before {
  left: 50%;
  top: 100%;
  -webkit-transform: translate3d(-50%, 0, 0) rotate(180deg);
  transform: translate3d(-50%, 0, 0) rotate(180deg);
}

.VechicleSkeletonDesktop
  .tooltipContainer[position='bottom'].wb-tooltip-host--flip:before,
.VechicleSkeletonDesktop
  .tooltipContainer[position='top']:not(.wb-tooltip-host--flip):before {
  bottom: 100%;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
}

.VechicleSkeletonDesktop
  .tooltipContainer[position='bottom'].wb-tooltip-host--flip:before,
.VechicleSkeletonDesktop
  .tooltipContainer[position='bottom']:not(.wb-tooltip-host--flip):before,
.VechicleSkeletonDesktop
  .tooltipContainer[position='end'].wb-tooltip-host--flip:before,
.VechicleSkeletonDesktop
  .tooltipContainer[position='end']:not(.wb-tooltip-host--flip):before,
.VechicleSkeletonDesktop
  .tooltipContainer[position='start'].wb-tooltip-host--flip:before,
.VechicleSkeletonDesktop
  .tooltipContainer[position='start']:not(.wb-tooltip-host--flip):before,
.VechicleSkeletonDesktop
  .tooltipContainer[position='top'].wb-tooltip-host--flip:before,
.VechicleSkeletonDesktop
  .tooltipContainer[position='top']:not(.wb-tooltip-host--flip):before {
  border-top: 8px solid var(--wb-grey-20);
  content: '';
  position: absolute;
  z-index: 1399;
}

.VechicleSkeletonDesktop .tooltipContainer .tooltipStyle {
  opacity: 1;
  pointer-events: all;
}

.VechicleSkeletonDesktop__image {
  width: 100%;
  height: auto;
}
.VechicleSkeletonDesktop__img {
  height: 40px;
}
.VechicleSkeletonDesktop__header {
  width: 100%;
}
.VechicleSkeletonDesktop__container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
}
.VechicleSkeletonDesktop__transition {
  position: absolute;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  background: linear-gradient(
      to right,
      #000000,
      #00000080,
      #00000000,
      #00000000
    ),
    no-repeat;
}
.VechicleSkeletonDesktop__title {
  color: white;
  position: absolute;
  top: 85px;
  left: 85px;
}

@media (max-width: 993px) {
  .VechicleSkeletonDesktop {
    display: none;
  }
}

.engineNavigatorDesktop {
  top: 48%;
  left: 34%;
  position: absolute;

  z-index: 10;
}

.gearNavigatorDesktop {
  top: 43%;
  left: 48%;
  position: absolute;

  z-index: 10;
}

.adBlueNavigatorDesktop {
  top: 58%;
  left: 54%;
  position: absolute;

  z-index: 10;
}
.axleOilNavigatorDesktop {
	top: 31%;
	left: 67%;
	position: absolute;
	z-index: 10;
}

.coolantNavigatorDesktop {
  top: 62%;
  left: 29%;
  position: absolute;

  z-index: 10;
}
