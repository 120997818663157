.wb-grid__zero-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.wb-content-slider__content {
  width: 85%;
  margin: 0 auto;
}
.wb-content-slider__arrow {
  background-color: #262626;
}
.wb-content-slider__arrow:active,
.wb-round-button--solid-level-2:not([disabled]):hover {
  background-color: #262626 !important;
  border-color: #262626 !important;
}
.wb-content-slider__arrow .wb-round-button__icon {
  color: #fff;
}

@media (max-width: 1023.98px) {
  .wb-content-slider__arrow {
    display: block !important;
  }
}

.wb-slider__arrow--right {
  margin-right: -166px;
}

.wb-slider__arrow--left {
  margin-left: -166px;
}

@media (max-width: 767px) {
  .CarSkeleton-tablet {
    display: none;
  }
}

@media (min-width: 993px) {
  .CarSkeleton-tablet {
    display: none;
  }
}
