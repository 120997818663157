.linkText {
  text-decoration: none;
  color: #bfbfbf;
  width: fit-content;
  margin-bottom: 8px;
}

.qrSheetNumbers {
  margin-right: 4px;
}

.qrSheetCollection {
  margin-top: 4px;
}

.linkContainer {
  text-decoration: none;
}

.qrPage {
  min-height: 530px;
  margin-top: 30px;
}

.qrTitle {
  margin-top: 12px;
  margin-bottom: 12px;
}

.qrTableContainer {
  margin-top: 8px;
  margin-bottom: 60px;
}

.qrLinkColletion {
  margin-bottom: -12px;
}
